import {
  // Box,
  // Flex,
  // Image,
  // Spacer,
  Text,
  // Button,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuGroup,
  useColorModeValue,
} from '@chakra-ui/react'
// import UserProfileLink from '@components/MainHeader/UserProfileLink'
// import NextLink from 'next/link'
import { useUserContext } from '@contexts/UserContext'
import AccountSwitcherButton from './AccountSwitcherButton'
// import Image from 'next/image'
import { useRouter } from 'next/router'

const Account = () => {
  const router = useRouter()
  const user = useUserContext()
  const { logout } = user
  // const loggedIn = user.token !== null
  // const name = user?.data?.isContact?.name
  // const pic = user?.settings?.profilePic?.value
  const email = user?.data?.hasEmail?.email
  return (
    <Menu>
      <AccountSwitcherButton />
      <MenuList shadow='lg' py='4' color={useColorModeValue('gray.600', 'gray.200')} px='3'>
        <Text fontWeight='medium' mb='2'>
          {email}
        </Text>
        <MenuDivider />
        <MenuGroup title='Tools'>
          <MenuItem value='contacts' fontWeight='semibold' rounded='md' onClick={() => router.push('/contacts')}>
            Contacts
          </MenuItem>
          <MenuItem value='tasks' fontWeight='semibold' rounded='md' onClick={() => router.push('/tasks')}>
            Tasks
          </MenuItem>
          <MenuItem value='calendar' fontWeight='semibold' rounded='md' onClick={() => router.push('/calendar')}>
            Calendar
          </MenuItem>
          <MenuItem value='finances' fontWeight='semibold' rounded='md' onClick={() => router.push('/finances')}>
            Finances
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuItem
          value='home'
          fontWeight='semibold'
          rounded='md'
          onClick={() => {
            window.location.href = '/home'
          }}
        >
          missionbase.com
        </MenuItem>
        <MenuDivider />
        <MenuItem rounded='md' onClick={() => router.push('/settings')}>
          Settings
        </MenuItem>
        {/* <MenuItem rounded='md'>Add an account</MenuItem>
        <MenuDivider /> */}
        <MenuItem rounded='md' onClick={() => logout()}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default Account
