import NextLink from 'next/link'
import { useUserContext } from '@contexts/UserContext'
import AccountMenu from './AccountMenu'
// import TrueFalse from '@components/Reusables/Logic/TrueFalse'
import {
  Box,
  Flex,
  Image,
  Spacer,
  Text,
  Button,
  ButtonGroup,
  useColorModeValue,
  Container,
  Divider,
  HStack,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  useBreakpointValue,
  Menu,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  MenuDivider,
  MenuItem,
  useDisclosure,
  MenuButton,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiMenu } from 'react-icons/fi'
import SaveColorMode from '@components/Reusables/Logic/SaveColorMode'
// import { Logo } from './Logo'

export default function Header() {
  return (
    <Box className='header'>
      {/* <Nav /> */}
      <Header2 />
    </Box>
  )
}

const Nav = () => {
  const logoURL = useColorModeValue(
    'https://missionbase-resources.s3.amazonaws.com/website/rocket+black+mb.png',
    'https://missionbase-resources.s3.amazonaws.com/website/rocket+white+mb.png'
  )
  const user = useUserContext()
  const loggedIn = user.token !== null
  return (
    <Flex
      className='nav'
      // bg='white'
      mx={[10, null, 20]}
      mt={[10]}
    >
      <Box mr={5}>
        <NextLink href='/'>
          <Image alt='MissionBase Logo' src={logoURL} height={31} quality={100} />
        </NextLink>
      </Box>
      <Spacer />
      <Menus links={links} />
      {/* <TrueFalse if={loggedIn}>
        <AccountMenu />
        <NextLink href='/contacts'>
          <Button primary>Login</Button>
        </NextLink>
      </TrueFalse> */}
    </Flex>
  )
}

const links = [
  {
    name: 'Home',
    to: '/',
  },
  // {
  //   name: 'About',
  //   to: '/about',
  // },
  {
    name: 'Features',
    to: '/features',
  },
  {
    name: 'Pricing',
    to: '/pricing',
  },
  // { name: 'Contacts', to: '/contacts' },
  // { name: 'Finances', to: '/finances' },
]

const Menus = (props) => {
  const { links } = props
  return links.map((link) => {
    return (
      <Text mr={5} key={link.name}>
        <NextLink href={link.to}>
          <Box>{link.name}</Box>
        </NextLink>
      </Text>
    )
  })
}

export const Header2 = () => {
  const logoURL = useColorModeValue(
    'https://missionbase-resources.s3.amazonaws.com/website/rocket+black+mb.png',
    'https://missionbase-resources.s3.amazonaws.com/website/rocket+white+mb.png'
  )
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  return (
    <Box as='section' pb={{ base: '12', md: '24' }}>
      <Box as='nav' bg='bg-surface' boxShadow={useColorModeValue('sm', 'sm-dark')}>
        <Container py={{ base: '4', lg: '5' }} maxW='7xl'>
          <HStack spacing='10' justify='space-between'>
            <NextLink href='/'>
              <Image alt='MissionBase Logo' src={logoURL} height={31} quality={100} />
            </NextLink>
            {/* <Logo /> */}
            {isDesktop ? (
              <Flex justify='space-between' flex='1'>
                <ButtonGroup variant='link' spacing='8'>
                  {links.map((item) => (
                    <NextLink href={item.to} key={item.to}>
                      <Button>{item.name}</Button>
                    </NextLink>
                  ))}
                </ButtonGroup>
                <HStack spacing='3'>
                  <SaveColorMode type='icon' />
                  <NextLink href='/contacts'>
                    <Button variant='outline'>Sign in</Button>
                  </NextLink>
                  {/* <Button variant='outline'>Sign in</Button> */}
                  {/* <Button variant="primary">Sign up</Button> */}
                </HStack>
              </Flex>
            ) : (
              <>
                <AccountSwitcher />
                {/* <Menus links={links} /> */}
              </>
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  )
}

export const AccountSwitcher = () => {
  // const { isOpen, onToggle } = useDisclosure()
  return (
    <Menu
      // isOpen={ isOpen }
      closeOnBlur
    >
      <MenuButton as={IconButton} aria-label='Options' icon={<FiMenu fontSize='1.25rem' />} variant='outline' />

      {/* <AccountSwitcherButton /> */}
      <MenuList shadow='lg' py='4' color={useColorModeValue('gray.600', 'gray.200')} px='3'>
        {/* <Text fontWeight='medium' mb='2'>
          joe.biden@chakra-ui.com
        </Text> */}
        {/* <MenuOptionGroup defaultValue='chakra-ui'>
          <MenuItemOption value='chakra-ui' fontWeight='semibold' rounded='md'>
            Features
          </MenuItemOption>
          <MenuItemOption value='careerlyft' fontWeight='semibold' rounded='md'>
            Pricing
          </MenuItemOption>
        </MenuOptionGroup> */}
        {/* <MenuDivider /> */}
        <NextLink href='/home'>
          <MenuItem rounded='md'>Home</MenuItem>
        </NextLink>
        <NextLink href='/features'>
          <MenuItem rounded='md'>Features</MenuItem>
        </NextLink>
        <NextLink href='/pricing'>
          <MenuItem rounded='md'>Pricing</MenuItem>
        </NextLink>
        <MenuDivider />
        <NextLink href='/contacts'>
          <MenuItem rounded='md'>Sign in</MenuItem>
        </NextLink>
      </MenuList>
    </Menu>
  )
}

// https://missionbase-resources.s3.amazonaws.com/website/white.png
// https://missionbase-resources.s3.amazonaws.com/website/Black.png
// https://missionbase-resources.s3.amazonaws.com/website/Black+Logo.png
// https://missionbase-resources.s3.amazonaws.com/website/White+Logo.png
// https://missionbase-resources.s3.amazonaws.com/website/White+logo+mobile.png
// https://missionbase-resources.s3.amazonaws.com/website/White+mobile.png
