import {
  Box,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Square,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import GoogleMap from '@components/Reusables/Design/GoogleMap'
import * as React from 'react'
// import { features } from './data'
import { BsFillMoonFill, BsStars } from 'react-icons/bs'
import { FaAccessibleIcon, FaExpandAlt, FaPaintBrush } from 'react-icons/fa'
import { IoRocketSharp } from 'react-icons/io5'

export const FeatureList = () => (
  <Box as='section' bg='bg-surface'>
    <Container py={{ base: '16', md: '24' }} maxW={'full'}>
      <Stack spacing={{ base: '12', md: '16' }} m={0}>
        <Stack spacing={{ base: '4', md: '5' }} align='center' textAlign='center'>
          <Stack spacing='3'>
            <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight='semibold' color='accent'>
              More Features
            </Text>
            <Heading size={useBreakpointValue({ base: 'sm', md: 'md' })}>What else can it do?</Heading>
          </Stack>
          <Text
            // color='muted'
            fontSize={{ base: 'lg', md: 'xl' }}
            maxW='3xl'
          >
            Built from the ground up with flexibility in mind to fit your workflow, no matter who you are.
          </Text>
        </Stack>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={8} rowGap={{ base: 10, md: 16 }}>
          {features.map((feature) => (
            <Stack key={feature.name} spacing={{ base: '4', md: '5' }} align='center' textAlign='center'>
              <Square size={{ base: '10', md: '12' }} bg='accent' color='inverted' borderRadius='lg'>
                <Icon as={feature.icon} boxSize={{ base: '5', md: '6' }} />
              </Square>
              <Stack spacing={{ base: '1', md: '2' }}>
                <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='medium'>
                  {feature.name}
                </Text>
                <Text color={mode('gray.600', 'gray.400')}>{feature.description}</Text>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Container>
  </Box>
)

export const features = [
  {
    name: 'Tasks',
    description: 'Connect tasks to directly to contacts, and filter them by attributes of the contact. What?!',
    icon: BsStars,
  },
  {
    name: 'Integrated Calendar',
    description: 'The most powerful calendar you\'ve ever used.',
    icon: IoRocketSharp,
  },
  {
    name: 'Auto-Dialer',
    description: 'Curate your list of contacts and hit "Go" to automatically call them directly from your browser. When you are done, the next call rings automatically! No more start-stop.',
    icon: BsFillMoonFill,
  },
  {
    name: 'Connect. All the ways.',
    description: "Dont just call. Text. Email. Send newsletters. Social media messaging. Spice it up. All from a single interface.",
    icon: FaPaintBrush,
  },
  {
    name: 'Financial Integrations',
    description: 'Connect your finances directly to your contacts, tasks, and calendar and enable powerful reporting and planning.',
    icon: FaExpandAlt,
  },
  {
    name: 'Much much more...',
    description: "Use your time to serve others, not bogged down in administrative duties. More time away from your computer means more time to accomplish your mission.",
    icon: FaAccessibleIcon,
  },
]
