import { Box, Flex, FlexProps, HStack, useColorModeValue, useMenuButton } from '@chakra-ui/react'
// import Icon from '@components/Reusables/Design/Icon'
import { useUserContext } from '@contexts/UserContext'
import * as React from 'react'
import Avatar from 'react-avatar'
// import { HiSelector } from 'react-icons/hi'

const AccountSwitcherButton = (props: FlexProps) => {
  const {
    username,
    data: { isContact: { name }, hasEmail: { email } } = { isContact: { name: undefined }, hasEmail: { email: undefined } },
    settings: { profilePic: { value: pic } = { value: undefined } } = { profilePic: { value: undefined } },
  } = useUserContext()
  const bg = useColorModeValue('mb_steel.400', 'gray.700')
  const bgActive = useColorModeValue('mb_steel.500', 'gray.600')
  const subTextColor = useColorModeValue('mb_steel.700', 'gray.400')
  const buttonProps = useMenuButton(props)
  return (
    <Flex
      as='button'
      {...buttonProps}
      //   w='full'
      display='flex'
      alignItems='center'
      rounded='lg'
      bg={bg}
      px='3'
      py='2'
      fontSize='sm'
      userSelect='none'
      cursor='pointer'
      outline='0'
      transition='all 0.2s'
      _active={{ bg: bgActive }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex='1' spacing='3'>
        <Avatar round size='24px' src={pic} name={name} email={pic ? null : email} alt='Profile Picture' />
        {/* <Img
          w='8'
          h='8'
          rounded='md'
          objectFit='cover'
          src={pic}
          //   src='https://images.unsplash.com/photo-1564564321837-a57b7070ac4f?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzV8fG1hbiUyMHNpbWxpbmd8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=100'
          alt='Account'
        /> */}
        <Box textAlign='start'>
          <Box noOfLines={1} fontWeight='semibold'>
            {name}
          </Box>
          <Box fontSize='xs' color={subTextColor}>
            {username}
          </Box>
        </Box>
      </HStack>
    </Flex>
  )
}
export default AccountSwitcherButton
