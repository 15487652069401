import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Img,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { MdCheckCircle, MdSettings } from 'react-icons/md'

export interface FeatureSectionProps {
  title: string
  subtitle: string
  img: FeatureSectionImg
  button?: FeatureSectionButton
  reverse?: boolean
  component?: any
  list?: string[]
}
export interface FeatureSectionImg {
  src: string
  alt: string
}
export interface FeatureSectionButton {
  text: string
  href: string
}
export function FeatureSection(props: FeatureSectionProps) {
  const { reverse, title, subtitle, button, img, component, list } = props
  return (
    <Box as='section' bg={mode('gray.50', 'gray.800')} py='10' overflow='hidden'>
      <Box maxW={{ base: 'xl', md: '7xl' }} mx='auto' px={{ base: '6', md: '8' }}>
        <Flex
          align='flex-start'
          direction={{ base: 'column', lg: reverse ? 'row-reverse' : 'row' }}
          // justify='space-between'
          justify='space-evenly'
          mb='10'
        >
          <Box flex='1' maxW={{ lg: 'xl' }} pt='6'>
            <Heading as='h2' size='2xl' mt='8' fontWeight='extrabold'>
              {title}
            </Heading>
            <Text color={mode('gray.600', 'gray.400')} mt='5' fontSize='xl'>
              {subtitle}
            </Text>
            {list && <BulletList list={list} />}
            {button && (
              <Button mt='8' minW='14rem' colorScheme='pink' size='lg' height='14' px='8' fontSize='md' fontWeight='bold'>
                {button?.text}
              </Button>
            )}
          </Box>
          {/* <Box boxSize={{ base: '20', lg: '8' }} /> */}
          <Img
            pos='relative'
            marginEnd={reverse ? null : '-16rem'}
            marginStart={reverse ? { base: null, lg: '-16rem' } : null}
            borderRadius={10}
            w='40rem'
            src={img?.src}
            alt={img?.alt}
          />
        </Flex>
      </Box>
    </Box>
  )
}

export function Features() {
  return (
    <>
      {featureSections.map((feature, i) => {
        const { reverse } = feature
        const flip = !!(reverse ?? i % 2) // automatically alternates layout for every other item
        return <FeatureSection key={i} {...feature} reverse={flip} />
      })}
    </>
  )
}

const featureSections: FeatureSectionProps[] = [
  {
    title: 'Contact info that is always up-to-date',
    subtitle:
      'Outdated address books are a thing of the past. Benefit from data that is always up to date so you can get right to work without having to worry if your data is good.',
    // img: { src: '/images/undraw_resume_1hqp.svg', alt: 'screenshot' },
    img: { src: '/images/undraw_personal_data_re_ihde.svg', alt: 'screenshot' },
    // component: () => <BulletList list={['te']} />,
    list: [
      'Contact that is effortlessly correct',
      'Data points that are verified by humans',
      'Privacy aware with private and group sharing options',
      'Intelligent data enrichment that fills in missing pieces automatically',
      'Enable/Disable groups of contacts to focus on your target',
    ],
    // button: { text: 'Request a Demo', href: '' },
    // reverse: false
  },
  {
    title: 'Social and Collaborative',
    subtitle:
      'Outdated address books are a thing of the past. Benefit from data that is always up to date so you can get right to work without having to worry if your data is good.',
    img: { src: '/images/undraw_online_connection_6778.svg', alt: 'screenshot' },
    // component: () => <BulletList list={['te']} />,
    list: [
      'Create groups to work with',
      'Share private notes and contact info only with group',
      'Join groups for additional data points that are relevant to the group',
      'See and share activity of those in your organization (optional)',
      'Activity heatmaps for each contact',
    ],
    // button: { text: 'Request a Demo', href: '' },
    // reverse: true
  },
  {
    title: 'Powerful Automations',
    subtitle: 'Power through your work with less friction.',
    img: { src: '/images/undraw_done_re_oak4.svg', alt: 'screenshot' },
    list: [
      'Auto-Dialer right from the browser',
      'Send physical postcards with a click',
      'Email Templates that work with any email address',
      'Send newsletter templates with a click',
      'Workflows and Pipline management',
    ],
    // button: { text: '', href: '' },
    // reverse: true,
  },
  {
    title: 'Integrates with you',
    subtitle:
      'Integrations with industry standard formats ensure that you will be able to use and work with your data no matter where you are.',
    img: { src: '/images/undraw_mind_map_re_nlb6.svg', alt: 'screenshot' },
    list: [
      'Syncs natively with all of your devices and apps',
      'Unified financial view',
      'See your calendar and contacts anywhere',
      'Additional integrations available through our API, and more coming all the time',
    ],
    // button: { text: '', href: '' },
    // reverse: true
  },
  {
    title: 'Dashboards',
    subtitle: 'Visualize and analyze your progress.',
    img: { src: '/images/undraw_charts_re_5qe9.svg', alt: 'screenshot' },
    list: [
      'Project your goal date',
      'Find meanigful metrics to analyze ways to improve efficiency',
      "Stay motivated by seeing your team's progress",
      'Daily dashboard to show you exactly what has happend and where to get started',
      'Financial dashboard to efficively plan and budget',
    ],
    // button: { text: '', href: '' },
    // reverse: true
  },
  {
    title: 'Powerful segmentation',
    subtitle: 'Find connections with our social graph that combines 100+ data points on any contact or organization. ',
    img: { src: '/images/undraw_filter_re_sa16.svg', alt: 'screenshot' },
    list: [
      'Create complex nested filters based on deeply nested attributes',
      'Save complex filters that are unique to you',
      'Easily combine & toggle saved filters to quickly include or exclude results',
      'Filter by any relationship or attribute--or relationships of relationships',
      'Join other groups to access even more niche data points',
    ],
    // button: { text: '', href: '' },
    // reverse: true,
  },
]
interface BulletListProps {
  list: string[]
}
export const BulletList = (props: BulletListProps) => {
  const { list } = props
  return (
    <List spacing={3} py={10} ml={5}>
      {list?.map((item) => (
        <ListItem key={item}>
          <ListIcon as={MdCheckCircle} color='green.500' />
          {item}
        </ListItem>
      ))}
    </List>
  )
}
